.initial-display {
  padding: 3rem 0rem;
  position: relative;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  color: white;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* z-index: -2; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  /* width: 50%; */
  margin: 0;
  position: relative;
}

.initial-display h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../assets/images/heading-dec-white.png");
  background-size: 40px;
  position: absolute;
  top: -30px;
  width: 40px;
  height: 40px;
}

.initial-display p {
  font-family: "Nunito";
  font-size: larger;
}

.initial-text {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  text-align: center;
}

.masked-logo {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  z-index: -1;
}

.masked-logo img {
  position: relative;
  width: 70%;
}

.breed-footer {
  display: grid;
  grid-template-columns: min-content 100%;
  width: 100%;
}

.breed-footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-handles {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.social-handles svg {
  margin-bottom: 1rem;
}

/* .drawer-open {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
} */

/* Member Details */

.member-details {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin: 4rem 4rem;

  /* hamza changes  */
  /* padding: 95px; */
}

.view-controller {
  min-width: max-content;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);

  /* hamza changes   */
  /* width: 25%;
  height: max-content; */
}

.view-controller > div {
  padding: 1rem 2rem;
  font-family: "Crimson Text";
  font-weight: 600;
  font-size: 20px;
  color: #5f5f5f;
  border-top: 2px solid #eeeeee;
}

.view-controller .active {
  color: #0f2d25;
  border-left: 3px solid #0f2d25;
  background-color: #e8eeed;
}

.breed-details {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  padding: 2rem 2rem;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  min-width: 75%;
  /* width: 70%; */
  /* border-radius: 20px; */

  /* hamza changes  */
  /* width: 75%; */
}

.breed-details > div > h1 {
  margin: 0.5rem 0;
  font-size: 34px;
  font-family: "Crimson Text";
}

/* .breed-image {
  display: flex;
  align-items: flex-start;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.breed-image img {
  width: 100%;
}

.breed-image {
  border-radius: 20px;
}

.breed-table {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin: 1rem 0;
}

.breed-table table {
  border-spacing: 10px;
  width: max-content;
}

.breed-table td:first-child {
  font-family: "Crimson Text";
  font-size: 20px;
  font-weight: bold;
}

.breed-table td:last-child {
  color: #5f5f5f;
  font-family: "Numnito";
  font-size: 18px;
}

.breed-table h1 {
  font-family: "Crimson Text";
}

.rounded-buttons {
  display: flex;
  margin-top: 1rem;
  column-gap: 20px;
}

.rounded-buttons button {
  font-size: 16px;
  color: #0f2d25;
  padding: 5px 10px;
  border: 1px solid #0f2d25;
  background: rgba(31, 255, 195, 0.1);
  border-radius: 100px;
}

.member-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
}

.member-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  column-gap: 10px;
  margin-top: 2rem;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: max-content;
}

.member-card img {
  width: 40px;
}

.member-card h2 {
  text-transform: capitalize;
  font-family: "Nunito";
  margin: 0;
}

.bottom {
  margin-top: 2rem;
}

.btn {
  display: flex;
  overflow: visible;
  background-color: transparent;
  border: none;
  width: max-content;
  position: relative;
  padding: 1rem 2rem;
}

.btn-text {
  display: flex;
  align-items: center;
  transition: color 1s;
  font-size: larger;
  color: #0f2d25;
}

.btn::before {
  border-radius: 100px;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 30%;
  height: 100%;
  background: rgba(192, 161, 71, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover::before {
  width: 100%;
  background-color: #0f2d25;
}

.btn:hover .btn-text {
  color: white;
}

.btn span {
  z-index: 10;
} */

/* @media screen and (max-width: 1200px) {
  .breed-table {
    flex-direction: column;
  }
} */

@media screen and (max-width: 900px) {
  .hide {
    display: none;
  }

  .breed-image {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    /* max-width: 20%; */
  }

  .member-details {
    margin: 4rem auto;
    max-width: 90%;
  }

  .breed-details {
    border-radius: 20px;
    max-width: 100%;
  }

  /* .drawer-open button {
    display: inline !important;
  } */
}

@media screen and (max-width: 500px) {
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .member-details {
    margin: 4rem auto;
    width: 90%;
  }

  .breed-details {
    width: 100%;
  }

  /* .breed-table {
    align-items: center;
  }

  .breed-table table {
    width: min-content;
  }

  .member-cards {
    justify-content: center;
  }

  .member-card {
    width: 80%;
    justify-content: center;
  }

  .rounded-buttons {
    align-self: center;
  }

  .bottom {
    align-self: center;
  } */
}

@media screen and (max-width: 460px) {
  .initial-display h1 {
    width: min-content;
  }
}

@media screen and (max-width: 400px) {
  .member-details {
    margin: 4rem auto;
    width: 100% !important;
  }

  .breed-details {
    box-sizing: content-box;
    width: 100%;
    padding: 2rem 0.5rem;
  }
}

@media screen and (max-width: 321px) {
  .initial-text {
    padding: 0;
  }
}

/* @media screen and (max-width: 500px) {
  .member-cards {
    width: 100%;
    justify-content: center;
  }

  .breed-details {
    flex-direction: column;
    align-items: center;
  }

  .breed-image {
    width: 90%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
} */

/* @media screen and (max-width: 426px) {
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
} */
