body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rodal-close {
  width: 33.33px;
  height: 33.33px;
  /* top: 55px;
  right:75px; */
  /* top: 10px;
  right:60px; */
  top: 25px;
  right: 40px;
  border-radius: 100px;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
}

.rodal-close:before,
.rodal-close:after {
  height: 4px;
  width: 55%;
  left: 8px;
  background-color: white;
}

.accordion-item {
  background: #ffffff;
  box-shadow: 0px 2px 30px rgb(0 0 0 / 15%);
  border-radius: 5px;
  margin-bottom: 15px;
}
.accordion-button {
  background-color: white !important;
  font-family: "Crimson Text";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #0f2d25 !important;
}

.EZDrawer {
  z-index: 10000;
}

@media screen and (max-width: 767px) {
  .accordion-button {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 425px) {
  .rodal-dialog {
    width: 90% !important;
  }
}

.toast-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .toast-container {
    transform: none;
  }
}
