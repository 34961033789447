.main {
  /* padding: 4rem 4rem; */
}

.initial-display {
  padding: 3rem 0rem;
  position: relative;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  color: white;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* z-index: -2; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  width: 50%;
  margin: 0;
}

.initial-display p {
  font-family: "Nunito";
}

.initial-text {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  text-align: center;
}

.masked-logo {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  /* z-index: -1; */
}

.masked-logo img {
  position: relative;
  width: 70%;
}

.breed-footer {
  display: grid;
  grid-template-columns: min-content 100%;
  width: 100%;
}

.breed-footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-handles {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.social-handles svg {
  margin-bottom: 1rem;
}

.stack {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.vstack {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hstack {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .stack {
    flex-direction: column;
  }

  .vstack {
    align-items: center;
  }
}

@media screen and (max-width: 426px) {
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

@media screen and (max-width: 321px) {
  .initial-text {
    padding: 0;
  }
}

.breed-details {
  display: flex;
  column-gap: 20px;
  padding: 2rem 2rem;
  border-radius: 20px;
  /* box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15); */
}

.breed-image {
  display: flex;
  align-items: flex-start;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  border-radius: 50%;
  overflow: hidden;
  /* max-width: 20%; */
}

.breed-image img {
  width: 100%;
}

.breed-image {
  border-radius: 20px;
}

.breed-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.breed-table table {
  border-spacing: 10px;
  width: max-content;
  /* border-spacing: 20px 20px; */
}

.breed-table td:first-child {
  font-weight: bold;
}

.breed-table h1 {
  font-family: "Crimson Text";
}

.bottom {
  align-self: flex-end;
}

.rounded-buttons {
  display: flex;
  /* justify-content: center; */
  margin-top: 1rem;
  column-gap: 2rem;
  align-items: center;
}

.rounded-buttons button {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 12px 24px;
  border: none;
  background: linear-gradient(45deg, #0f2d25, #1a664f);
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.rounded-buttons button:hover {
  background: linear-gradient(45deg, #1a664f, #0f2d25);
  transform: scale(1.05);
}

/* Add a subtle shadow for depth */
.rounded-buttons button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(15, 45, 37, 0.4);
}

.member-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  /* width: 70%; */
}

.member-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  column-gap: 10px;
  margin-top: 2rem;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: max-content;
}

.linkStyle {
  text-decoration: none;
  color: inherit;
  font-size: smaller;
}

.linkStyle:hover {
  text-decoration: underline;
}

.member-card img {
  width: 40px;
}

.member-card h2 {
  margin: 0;
}

@media screen and (max-width: 800px) {
  /* .main {
    padding: 4rem 4rem;
  } */

  .breed-table {
    align-items: center;
  }

  .breed-details {
    flex-direction: column;
    align-items: center;
  }

  .breed-details h1 {
    text-align: center;
  }

  .breed-image {
    width: 50%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  /* .main {
    padding: 4rem 1rem;
  } */
  .rounded-buttons {
    display: grid;
    gap: 1rem;
  }

  .member-cards {
    /* width: 100%; */
    justify-content: center;
    /* width: min-content; */
  }

  .breed-details {
    flex-direction: column;
    align-items: center;
  }

  .breed-image {
    width: 90%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}
