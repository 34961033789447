@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&family=Nunito&display=swap");
.navbar {
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg,#0f2d25 11.2%,#217a77);;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar img {
  /* width: min-content; */
  width: 8%;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: white;
}

.navbar li {
  margin-left: 1rem;
  margin-right: 1rem;
  float: left;
}

.navbar a {
  text-decoration: none;
  color: white;
}

/* Global */
/* .inverse-button {
  border: 1px solid white;
  color: white;
  padding: 0.7rem 1rem;
  border-radius: 100px;
  background-color: transparent;
} */

.inverse-button {
  border: none;
  border: 1px solid white;
  background-color: transparent; /* Modern color */
  color: white;
  padding: 0.7rem 2rem;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth hover transition */
}

.logged-in-buttons {
  display: flex;
  column-gap: 1rem;
}

.logged-in-buttons button {
  cursor: pointer;
}

/* Imported Dropdown */

.dropdown {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.dropdown-icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.dropdown-logo {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem 0 1.5rem;
  align-items: center;
  cursor: pointer;
}

.dropdown-logo img {
  width: 35%;
}

#dropdown-box {
  /* background: linear-gradient(178.89deg, #52bd94 0.95%, #00505d 101.59%); */
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  border: 1px solid #00505d;
  padding-right: 0.3rem;
}

#dropdown-box a {
  text-decoration: none;
  color: white;
}

/* .dropdown-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
} */

.dropdown-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 100%;
  /* border-radius: 5px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  /* z-index: 1001; */
}

.dropdown-list-child {
  left: 10%;
}

.dropdown-buttons {
  display: flex !important;
  margin-top: 0.5rem !important;
  justify-content: space-between !important;
}

.dropdown-buttons button {
  font-size: medium;
  text-transform: uppercase;
}

/* .color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #00505d;
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  margin-left: 1rem;
  font-family: "DM Sans";
} */

/* .color-inverse-button {
  border-radius: 8px;
  color: #00505d;
  background-color: white;
  font-size: 18px;
  border: none;
  padding: 0.7rem 1.5rem;
  margin-left: 1rem;
  font-family: "DM Sans";
} */

/* Imported Dropdown */

@media screen and (max-width: 1025px) {
  .navbar img {
    width: 10%;
  }
  .navbar li {
    font-size: small;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .inverse-button {
    padding: 0.7rem 1rem;
  }
}

@media screen and (max-width: 769px) {
  .dropdown {
    display: flex;
  }

  .hidden-mobile {
    display: none;
  }

  .navbar {
    justify-content: space-between;
    padding: 0 2rem;
    background: linear-gradient(
      90deg,
      rgba(15, 45, 37, 1) 0%,
      rgba(23, 78, 73, 1) 100%
    );
  }

  .navbar img {
    width: 20%;
  }
}

@media screen and (max-width: 550px) {
  .navbar img {
    width: 35%;
  }
}

.nested-dropdowns {
  /* Border */
  /* border: 1px solid #d1d5db; */
  display: flex;

  /* Reset list styles */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nested-dropdowns li {
  cursor: pointer;

  /* Spacing */
  padding: 0.25rem;

  /* Used to position the sub nested-dropdowns */
  position: relative;
}

/* The sub nested-dropdowns */
.nested-dropdowns ul {
  /* Border */
  width: 200px;
  /* Hidden by default */
  display: none;

  /* Absolute position */
  /* left: -70px; */
  position: absolute;
  top: 100%;

  /* Reset styles */
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  border-radius: 20px;
  text-align: center;
}

.nested-dropdowns ul li {
  /* width: 150px; */
  font-size: 16px;
}
/* The second level sub nested-dropdowns */
.nested-dropdowns ul ul {
  left: 100%;
  position: absolute;
  top: 0;
}

/* Change background color of list item when being hovered */
.nested-dropdowns li:hover {
  background-color: transparent;
}

/* Show the direct sub nested-dropdowns when hovering the list item */
.nested-dropdowns li:hover > ul {
  display: block;
}
