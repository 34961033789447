* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v212_920 {
  width: 100%;
  height: 1695px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v212_921 {
  width: 100%;
  height: 315px;
  /* background: url("../images/v212_921.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v212_922 {
  width: 100%;
  height: 315px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}
.v212_923 {
  width: 100%;
  height: 314px;
  background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
  opacity: 1;
  position: absolute;
  top: 315px;
  left: 1440px;
  transform: rotate(-179deg);
}
.v212_924 {
  width: 138px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 243px;
  left: 645px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 24px;
  opacity: 1;
  text-align: left;
}
.v212_925 {
  width: 100%;
  height: 75px;
  /* background: url("../images/v212_925.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 14px;
  left: 90px;
  overflow: hidden;
}
.v212_926 {
  width: 141px;
  height: 75px;
  /* background: url("../images/v212_926.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.v212_928 {
  width: 333px;
  height: 341px;
  /* background: url("../images/v212_928.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.03999999910593033;
  position: absolute;
  top: 31px;
  left: 20px;
  overflow: hidden;
}
.v212_929 {
  width: 333px;
  height: 341px;
  /* background: url("../images/v212_929.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v212_930 {
  width: 317px;
  height: 330px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 11px;
  left: 16px;
  border: 0.5px solid rgba(255,255,255,1);
}
.v212_931 {
  width: 23px;
  height: 67px;
  /* background: url("../images/v212_931.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 202px;
  left: 90px;
  overflow: hidden;
}
.v212_932 {
  width: 23px;
  height: 23px;
  /* background: url("../images/v212_932.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v212_933 {
  width: 23px;
  height: 23px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v212_934 {
  width: 15px;
  height: 10px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 4px;
}
.v212_935 {
  width: 23px;
  height: 23px;
  /* background: url("../images/v212_935.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 43px;
  left: 0px;
  overflow: hidden;
}
.v212_936 {
  width: 23px;
  height: 23px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.v212_937 {
  width: 6px;
  height: 12px;
  /* background: url("../images/v212_937.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 8px;
  overflow: hidden;
}
.v212_938 {
  width: 6px;
  height: 12px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v212_939 {
  width: 393px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 173px;
  left: 516px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 48px;
  opacity: 1;
  text-align: left;
}
.v212_940 {
  width: 38px;
  height: 37px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 173px;
  left: 956px;
  overflow: hidden;
}
.v212_941 {
  width: 38px;
  height: 37px;
  /* background: url("../images/v212_941.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v212_942 {
  width: 4px;
  height: 17px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 37px;
}
.v212_943 {
  width: 24px;
  height: 29px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 7px;
  left: 6px;
}
.v212_944 {
  width: 24px;
  height: 7px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v212_947 {
  width: 688px;
  height: 19px;
  /* background: url("../images/v212_947.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 40px;
  left: 377px;
  overflow: hidden;
}
.v212_948 {
  width: 48px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v212_949 {
  width: 103px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 585px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v212_950 {
  width: 63px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 484px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v212_951 {
  width: 54px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 390px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v212_952 {
  width: 79px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 272px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v212_953 {
  width: 142px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 0px;
  left: 88px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v215_1255 {
  width: 20%;
  height: auto;
  float:left;
  clear:both;
  background: rgba(255,255,255,1);
  /* background-repeat: no-repeat; */
  /* background-position: center center; */
  /* background-size: cover; */
  opacity: 1;
  /* position: absolute; */
  top: 387px;
  /* left: 90px; */
  overflow: hidden;
}
.v215_1256 {
  width: 20%;
  height: auto;
  background: rgba(255,255,255,1);
  opacity: 1;
  float:left;
  margin-left:10px;
  /* margin-right:10px; */
  margin-bottom:10px;
  margin-top:20px;
  /* position: absolute; */
  /* top: 0px;
  left: 0px; */
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.10000000149011612);
  overflow: hidden;
}
.v215_1257 {
  width: 100%;
  text-align:center;
  opacity: 1;
  overflow: hidden;
  border: 1px solid black;
  padding: 5px;
}
.v215_1258 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1259 {
  height: 68px;
  /* background: url("../images/v215_1259.png"); */
  opacity: 1;
  /* position: absolute; */
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1260 {
  width: 100%;
  color: rgba(94,94,94,1);
  /* position: absolute; */
  /* top: 22px; */
  /* left: 38px; */
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
}
.v215_1261 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1261.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 476px;
  left: 0px;
  overflow: hidden;
}
.v215_1262 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1263 {
  height: 68px;
  /* background: url("../images/v215_1263.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1264 {
  width: 134px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1265 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1265.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 544px;
  left: 0px;
  overflow: hidden;
}
.v215_1266 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1267 {
  height: 68px;
  /* background: url("../images/v215_1267.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1268 {
  width: 152px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1269 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1269.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 340px;
  left: 0px;
  overflow: hidden;
}
.v215_1270 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1271 {
  height: 68px;
  /* background: url("../images/v215_1271.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1272 {
  width: 192px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1273 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1273.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 204px;
  left: 0px;
  overflow: hidden;
}
.v215_1274 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1275 {
  height: 68px;
  /* background: url("../images/v215_1275.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1276 {
  width: 161px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1320 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1320.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 270px;
  left: 0px;
  overflow: hidden;
}
.v215_1321 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1322 {
  height: 68px;
  /* background: url("../images/v215_1322.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1323 {
  width: 125px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1277 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1277.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 136px;
  left: 0px;
  overflow: hidden;
}
.v215_1278 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1279 {
  height: 68px;
  /* background: url("../images/v215_1279.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1280 {
  width: 138px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1285 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1285.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v215_1286 {
  width: 296px;
  height: 68px;
  background: rgba(255,255,255,1);
  opacity: 0.10000000149011612;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1287 {
  height: 68px;
  /* background: url("../images/v215_1287.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid rgba(238,238,238,1);
}
.v215_1288 {
  width: 180px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1289 {
  width: 296px;
  height: 476px;
  /* background: url("../images/v215_1289.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 68px;
  left: 0px;
  overflow: hidden;
}
.v215_1290 {
  width: 296px;
  /* background: url("../images/v215_1290.png"); */
  opacity: 1;
  position: absolute;
  top: 272px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1291 {
  width: 296px;
  /* background: url("../images/v215_1291.png"); */
  opacity: 1;
  position: absolute;
  top: 340px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1292 {
  width: 296px;
  /* background: url("../images/v215_1292.png"); */
  opacity: 1;
  position: absolute;
  top: 408px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1293 {
  width: 296px;
  /* background: url("../images/v215_1293.png"); */
  opacity: 1;
  position: absolute;
  top: 476px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1294 {
  width: 296px;
  /* background: url("../images/v215_1294.png"); */
  opacity: 1;
  position: absolute;
  top: 204px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1295 {
  width: 296px;
  /* background: url("../images/v215_1295.png"); */
  opacity: 1;
  position: absolute;
  top: 136px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1296 {
  width: 296px;
  /* background: url("../images/v215_1296.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1297 {
  width: 296px;
  /* background: url("../images/v215_1297.png"); */
  opacity: 1;
  position: absolute;
  top: 68px;
  left: 0px;
  border: 1px solid rgba(238,238,238,1);
}
.v215_1281 {
  width: 296px;
  height: 68px;
  /* background: url("../images/v215_1281.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 68px;
  left: 0px;
  overflow: hidden;
}
.v215_1282 {
  width: 296px;
  height: 68px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 0.10000000149011612;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v215_1283 {
  height: 68px;
  /* background: url("../images/v215_1283.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 1px;
  border: 3px solid linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
}
.v215_1284 {
  width: 128px;
  color: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  position: absolute;
  top: 22px;
  left: 38px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 20px;
  opacity: 1;
  text-align: left;
}
.v215_1249 {
  width: 75%;
  /* margin: 20px; */
  margin-right: 10px;
  margin-top: 20px;
  height: auto;
  background: rgba(255,255,255,1);
  opacity: 1;
  float:right;
  /* position: absolute; */
  /* top: 387px;
  left: 406px; */
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15000000596046448);
}
.v215_1307 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 613px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1031 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 664px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1044 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 715px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1057 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 766px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1070 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 817px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1083 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 868px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1096 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 919px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1109 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 970px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1122 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 1021px;
  left: 458px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1018 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 613px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1032 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 664px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1045 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 715px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1058 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 766px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1071 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 817px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1084 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 868px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1097 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 919px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1110 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 970px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1123 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 1021px;
  left: 605px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1019 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 613px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1033 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 664px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1046 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 715px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1059 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 766px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1072 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 817px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1085 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 868px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1098 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 919px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1111 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 970px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1124 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 1021px;
  left: 752px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1022 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 621px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1034 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 672px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1047 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 723px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1060 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 774px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1073 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 825px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1086 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 876px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1099 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 927px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1112 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 978px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1125 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 1029px;
  left: 1046px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1023 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 620px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1035 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 671px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1048 {
  width: 46px;
  color: rgba(192,161,71,1);
  position: absolute;
  top: 722px;
  left: 1171px;
  font-family: Nunito;
  font-weight: SemiBold;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1061 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 773px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1074 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 824px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1087 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 875px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1100 {
  width: 46px;
  color: rgba(192,161,71,1);
  position: absolute;
  top: 926px;
  left: 1171px;
  font-family: Nunito;
  font-weight: SemiBold;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1113 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 977px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1126 {
  width: 31px;
  color: rgba(15,45,37,1);
  position: absolute;
  top: 1028px;
  left: 1178px;
  font-family: Nunito;
  font-weight: Medium;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1029 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1029.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 612px;
  left: 899px;
  overflow: hidden;
}
.v220_1020 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1021 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1037 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1037.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 663px;
  left: 899px;
  overflow: hidden;
}
.v220_1038 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1039 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1050 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1050.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 714px;
  left: 899px;
  overflow: hidden;
}
.v220_1051 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1052 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1063 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1063.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 765px;
  left: 899px;
  overflow: hidden;
}
.v220_1064 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1065 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1076 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1076.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 816px;
  left: 899px;
  overflow: hidden;
}
.v220_1077 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1078 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1089 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1089.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 867px;
  left: 899px;
  overflow: hidden;
}
.v220_1090 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1091 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1102 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1102.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 918px;
  left: 899px;
  overflow: hidden;
}
.v220_1103 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1104 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1115 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1115.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 969px;
  left: 899px;
  overflow: hidden;
}
.v220_1116 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1117 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1128 {
  width: 100px;
  height: 33px;
  /* background: url("../images/v220_1128.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1020px;
  left: 899px;
  overflow: hidden;
}
.v220_1129 {
  width: 100px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v220_1130 {
  width: 92px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 17px;
  left: 4px;
  font-family: Inter;
  font-weight: Regular;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v217_1008 {
  width: 877px;
  height: 36px;
  background: rgba(192,161,71,1);
  opacity: 1;
  position: absolute;
  top: 563px;
  left: 438px;
}
.v215_1301 {
  width: 28px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 494px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1012 {
  width: 34px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 638px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1013 {
  width: 87px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 759px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1014 {
  width: 90px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 904px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1015 {
  width: 76px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 1054px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1016 {
  width: 43px;
  color: rgba(255,255,255,1);
  position: absolute;
  top: 571px;
  left: 1172px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 17px;
  opacity: 1;
  text-align: center;
}
.v220_1024 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 621px;
  left: 1262px;
  overflow: hidden;
}
.v220_1025 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1025.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1026 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1027 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1040 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 672px;
  left: 1262px;
  overflow: hidden;
}
.v220_1041 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1041.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1042 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1043 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1053 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 723px;
  left: 1262px;
  overflow: hidden;
}
.v220_1054 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1054.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1055 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1056 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1066 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 774px;
  left: 1262px;
  overflow: hidden;
}
.v220_1067 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1067.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1068 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1069 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1079 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 825px;
  left: 1262px;
  overflow: hidden;
}
.v220_1080 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1080.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1081 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1082 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1092 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 876px;
  left: 1262px;
  overflow: hidden;
}
.v220_1093 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1093.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1094 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1095 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1105 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 927px;
  left: 1262px;
  overflow: hidden;
}
.v220_1106 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1106.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1107 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1108 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1118 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 978px;
  left: 1262px;
  overflow: hidden;
}
.v220_1119 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1119.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1120 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1121 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v220_1131 {
  width: 16px;
  height: 16px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 1029px;
  left: 1262px;
  overflow: hidden;
}
.v220_1132 {
  width: 16px;
  height: 11px;
  /* background: url("../images/v220_1132.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 0px;
  overflow: hidden;
}
.v220_1133 {
  width: 5px;
  height: 5px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
}
.v220_1134 {
  width: 16px;
  height: 11px;
  background: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.name {
  color: #fff;
}
.v237_1058 {
  width: 877px;
  height: 408px;
  /* background: url("../images/v237_1058.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 654px;
  left: 438px;
  overflow: hidden;
}
.v220_1030 {
  width: 877px;
  /* background: url("../images/v220_1030.png"); */
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1036 {
  width: 877px;
  /* background: url("../images/v220_1036.png"); */
  opacity: 1;
  position: absolute;
  top: 51px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1049 {
  width: 877px;
  /* background: url("../images/v220_1049.png"); */
  opacity: 1;
  position: absolute;
  top: 102px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1062 {
  width: 877px;
  /* background: url("../images/v220_1062.png"); */
  opacity: 1;
  position: absolute;
  top: 153px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1075 {
  width: 877px;
  /* background: url("../images/v220_1075.png"); */
  opacity: 1;
  position: absolute;
  top: 204px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1088 {
  width: 877px;
  /* background: url("../images/v220_1088.png"); */
  opacity: 1;
  position: absolute;
  top: 255px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1101 {
  width: 877px;
  /* background: url("../images/v220_1101.png"); */
  opacity: 1;
  position: absolute;
  top: 306px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1114 {
  width: 877px;
  /* background: url("../images/v220_1114.png"); */
  opacity: 1;
  position: absolute;
  top: 357px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1127 {
  width: 877px;
  /* background: url("../images/v220_1127.png"); */
  opacity: 1;
  position: absolute;
  top: 408px;
  left: 0px;
  border: 0.20000000298023224px solid rgba(94,94,94,1);
}
.v220_1135 {
  width: 244px;
  height: 28px;
  /* background: url("../images/v220_1135.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1092px;
  left: 754px;
  overflow: hidden;
}
.v220_1136 {
  width: 27px;
  height: 28px;
  /* background: url("../images/v220_1136.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 6px 8px;
  margin: 5px;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 57px;
  border-top-right-radius: 57px;
  border-bottom-left-radius: 57px;
  border-bottom-right-radius: 57px;
  overflow: hidden;
}
.v220_1137 {
  width: 11px;
  height: 11px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 19px;
  left: 19px;
  transform: rotate(-180deg);
  overflow: hidden;
}
.v220_1138 {
  width: 5px;
  height: 9px;
  background: rgba(188,188,188,1);
  opacity: 1;
  position: absolute;
  top: 2px;
  left: 4px;
}
.name {
  color: #fff;
}
.v220_1140 {
  width: 12px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 189px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1141 {
  width: 7px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 168px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1142 {
  width: 12px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 142px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1143 {
  width: 12px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 115px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1144 {
  width: 12px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 89px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1145 {
  width: 12px;
  color: rgba(94,94,94,1);
  position: absolute;
  top: 6px;
  left: 63px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.v220_1146 {
  width: 11px;
  color: linear-gradient(rgba(15,45,37,1), rgba(33,122,118,1));
  position: absolute;
  top: 6px;
  left: 39px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 11px;
  opacity: 1;
  text-align: left;
}
.name {
  color: #fff;
}
.v215_1250 {
  width: 89px;
  height: 27px;
  background: rgba(30,255,195,0.10000000149011612);
  padding: 9px 13px;
  margin: 25px;
  opacity: 1;
  float:right;
  /* position: absolute; */
  top: 436px;
  left: 1226px;
  border-top-left-radius: 69px;
  border-top-right-radius: 69px;
  border-bottom-left-radius: 69px;
  border-bottom-right-radius: 69px;
  overflow: hidden;
}
.v215_1251 {
  width: 61px;
  height: 22px;
  /* background: url("../images/v215_1251.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: relative;
  top: -5px;
  /* left: 13px; */
  overflow: hidden;
}
.v215_1252 {
  width: 6px;
  height: 6px;
  background: rgba(61,223,58,1);
  opacity: 1;
  position: absolute;
  top: 7px;
  left: 0px;
  border-radius: 50%;
}
.v215_1253 {
  width: 47px;
  color: rgba(62,223,59,1);
  position: absolute;
  top: 0px;
  left: 14px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 16px;
  opacity: 1;
  text-align: center;
}
.v215_1299 {
  width: 277px;
  color: rgba(15,45,37,1);
  position: relative;
  float:left;
  top: 10px;
  left: 0px;
  margin: 10px;
  font-family: Crimson Text;
  font-weight: SemiBold;
  font-size: 34px;
  opacity: 1;
  text-align: left;
}
.v215_1312 {
  width: 31px;
  height: 29px;
  /* background: url("../images/v215_1312.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 436px;
  left: 745px;
  overflow: hidden;
}
.v215_1313 {
  width: 4px;
  height: 13px;
  background: rgba(15,45,37,1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 30px;
}
.v215_1314 {
  width: 20px;
  height: 22px;
  background: rgba(15,45,37,1);
  opacity: 1;
  position: absolute;
  top: 6px;
  left: 5px;
}
.v215_1315 {
  width: 19px;
  height: 5px;
  background: rgba(15,45,37,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
