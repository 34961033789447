.stud-certificate-list {
  padding: 1rem 2rem;
  /* overflow-x: scroll; */
}

.stud-certificate-list ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 3rem;
}

.stud-certificate-list li {
  font-size: 18px;
  margin-left: 1rem;
  cursor: pointer;
  color: #0f2d25;
  float: left;
}

.initial-display {
  display: flex;
  justify-content: space-between;
  /* text-align: right; */
}

.initial-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.initial-text h2 {
  margin: 0;
  font-family: "Nunito";
  color: #c0a147;
}

.initial-text p {
  margin-top: 0;
  color: #c0a147;
  font-family: "Nunito";
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid transparent;
  transition: border 0.5s;
}

.initial-text p:hover {
  border-bottom: 1px solid #c0a147;
}

.stud-table {
  border-collapse: collapse;
  width: 100%;

  /* border-spacing: 10px 30px; */
}

.table-header {
  font-family: "Crimson Text";
  font-size: 17px;
  background-color: #c0a147;
  color: white;
}

.table-header th {
  padding: 10px;
}

.table-header th:first-child {
  border-top-left-radius: 10px;
}

.table-header th:last-child {
  border-top-right-radius: 10px;
}

.stud-table tr {
  border-bottom: 0.01rem solid #5f5f5f;
}

.stud-table td {
  text-align: center;
  padding: 10px;
  color: #5f5f5f;
  font-family: "Nunito";
}

.btn {
  /* align-items: center; */
  display: flex;
  overflow: visible;
  background-color: transparent;
  border: none;
  width: max-content;
  position: relative;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
}

.btn-text {
  display: flex;
  align-items: center;
  transition: color 1s;
  font-size: larger;
  color: #0f2d25;
}

.btn::before {
  border-radius: 100px;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 30%;
  height: 100%;
  background: rgba(192, 161, 71, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover::before {
  /* transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  width: 100%;
  background-color: #0f2d25;
}

.btn:hover .btn-text {
  color: white;
}

.btn span {
  z-index: 10;
}

@media screen and (max-width: 700px) {
  .stud-certificate-list {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 500px) {
  .initial-display {
    flex-direction: column;
    align-items: center;
  }

  .initial-text {
    align-items: center;
  }

  .stud-certificate-list {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 335px) {
  .initial-text h2 {
    text-align: center;
  }
}
