.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  color: white;
  font-family: "Nunito";
  background: linear-gradient(
    90deg,
    rgba(15, 45, 37, 1) 0%,
    rgba(23, 78, 73, 1) 100%
  );
  width: 100%;
  text-align: center;
  padding: 0 4rem;
  bottom: 0px;
}

.masked-logo {
  /* position: relative; */
  /* width: 100%; */
  position: absolute;
  top: 0;
  right: 0;
  max-width: 24%;
  transform: rotateY(180deg);
}
.masked-logo img {
  width: 100%;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-links span:not(:first-child)::before {
  margin: 0 10px;
  content: "|";
}

.stick::before {
  margin: 0 10px;
  content: "|";
}

.contact {
  display: flex;
  margin-top: 1rem;
}

.contact span {
  display: flex;
}

.social-handles {
  margin: 1rem 0;
}
.social-handles svg {
  margin: 0 5px;
}

.copyright {
  overflow: auto;
  font-family: "Nunito";
  text-align: center;
  color: #0f2d25;
  background-color: #c0a147;
  padding: 0 1rem;
}

@media screen and (max-width: 768px) {
  .contact {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: max-content;
    gap: 1rem;
  }
}

@media screen and (max-width: 426px) {
  .footer {
    padding: 0 2rem;
  }

  .masked-logo {
    display: none;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
  }

  .footer-links > div {
    margin-top: 1rem;
  }

  .stick::before {
    content: "";
    margin: 0;
  }

  .contact span {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .footer {
    padding: 0 1rem;
    justify-content: flex-start;
    /* align-items: f; */
  }

  .contact {
    text-align: left;
    width: fit-content;
    gap: 0.3rem;
  }
}
