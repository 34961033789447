.initial-display {
  padding: 3rem 0rem;
  position: relative;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  color: white;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* z-index: -2; */
  /* width: 100%; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  /* width: 50%; */
  position: relative;
}

.initial-display h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../assets/images/heading-dec-white.png");
  background-size: 50px;
  position: absolute;
  top: -35px;
  width: 50px;
  height: 50px;
}

.initial-display p {
  font-family: "Nunito";
  max-width: 1300px;
}

.initial-text {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  text-align: center;
  /* z-index: 1; */
  width: 100%;
}

.masked-logo {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  /* z-index: -1; */
}

.masked-logo img {
  position: relative;
  width: 70%;
}

.breed-footer {
  display: grid;
  grid-template-columns: min-content 100%;
  width: 100%;
  margin: 2rem 0 0 0;
}

.breed-footer > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.AboutDiv {
  /* display: "flex", flexDirection: "column", align-items: "center" */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: max-content; */
  width: 100%;
}

.breed-footer > div > button {
  font-size: 16px;
  background-color: white;
  color: #0f2d25;
  padding: 1rem 3rem;
  border-radius: 100px;
  border: none;
  margin-left: 1rem;
}

.social-handles {
  display: flex;
  flex-direction: column;
}

.social-handles svg {
  margin-bottom: 1rem;
}

@media screen and (max-width: 426px) {
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

@media screen and (max-width: 321px) {
  .initial-text {
    padding: 0;
  }
}
