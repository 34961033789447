.landing {
  overflow-x: none;
  min-height: 100vh;
  max-width: 100%;
  padding-top: 8;
  padding-bottom: 8;
}

.initial-display {
  margin: 0;
  background: linear-gradient(167deg, #0f2d25 11.2%, #217a77 100%);
  animation: gradientAnimation 4s linear infinite;
  color: white;
  justify-content: space-between;
}

.video-container {
  position: absolute;
  height: 100%;
  overflow: hidden;
  opacity: 1;
  right: 0;
  -webkit-right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
  /* width: 100%; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  position: relative;
  /* width: 80%; */
}

.initial-display h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../assets/images/heading-dec-white.png");
  background-size: 50px;
  position: absolute;
  top: -35px;
  width: 50px;
  height: 50px;
}

.initial-display p {
  font-family: "Nunito";
  position: relative;
}

.initial-image {
  display: flex;
  align-items: flex-end;
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: fit-content;
  height: 400px;
  /* border: 1px solid white; */
}

.carousel-image-img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.initial-text p {
  font-size: 20px;
  top: -30px;
  /* text-align: justify; */
  width: 70%;
}

.initial-text {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
  /* align-items: center; */
  width: 52%;
  padding: 3rem 0;

  /* &::before {
    content: "";
    position: absolute;
    left: 105.56%;
    top: 22.6%;
    width: 100%;
    height: 40%;
    border-left: 15px solid rgb(217, 166, 93);
    opacity: 0.7;
    transform: rotate(-20deg);
  } */
}

.btn {
  /* align-items: center; */
  display: flex;
  overflow: visible;
  background-color: transparent;
  border: none;
  width: max-content;
  position: relative;
  justify-content: center;
  margin-bottom: 1rem;
  /* margin-inline: auto; */
  padding: 0.5rem 0.5rem;
}

.btn-text {
  display: flex;
  align-items: center;
  transition: color 1s;
  font-size: larger;
  color: white;
}

.btn::before {
  border-radius: 100px;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 30%;
  height: 100%;
  background: #0f2d25;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5%;
}

.btn:hover::before {
  /* transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  width: 100%;
  background-color: white;
  padding: 0.5%;
}

.btn:hover .btn-text {
  color: black;
}

.btn span {
  z-index: 10;
}

.social-handles {
  margin: 1rem 0 0 0;
  position: relative;
}

.social-handles svg {
  margin-right: 1rem;
  color: white;
}

.initial-display img {
  width: 100%;
}

.container {
  margin: 5rem auto;
  margin-bottom: 2.5rem;
  padding: 0 4rem;
  /* width: 85%; */
}

.stud-cards {
  display: flex;
  column-gap: 50px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 2rem;
  /* height: max-content !important; */
}

.stud-cards > div {
  width: 40%;
  min-width: 300px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.stud-data-contents {
  padding: 0.5rem 1rem;
}

.stud-data-main-heading {
  font-family: "Crimson Text";
  margin: -0.5rem -1rem 1rem -1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  background-color: #0f2d25;
  color: white;
  padding: 1rem 0;
}
.stud-head {
  position: relative;
  width: 15%;
  margin: auto;
}
.stud-head:after {
  content: "";
  display: inline-block;
  background-image: url("../../assets/images/heading-dec-white.png");
  background-size: 25px;
  background-repeat: no-repeat;
  position: absolute;
  top: -13px;
  right: -13px;
  width: 30px;
  height: 30px;
}
.stud-cards img {
  width: 100%;
}

.stud-cards p {
  margin: 0.5rem 0;
}

.stud-data-heading {
  font-weight: bold;
  color: #0f2d25;
  font-family: "Crimson Text";
  /* margin-bottom: 1rem; */
}

.stud-data-content {
  color: #5f5f5f;
  font-family: "Nunito";
  font-size: larger;
  /* margin-bottom: 1rem; */
}

.stud-data-content a {
  text-decoration: none;
  color: #5f5f5f;
}

/* Global */
.main-heading {
  color: #0f2d25;
  font-family: "Crimson Text";
  font-size: 34px;
  position: relative;
  margin: 1rem auto;
  width: max-content;
}

.main-heading:after {
  content: "";
  display: inline-block;
  background-image: url("../../assets/images/heading-dec.png");
  background-size: 40px;
  position: absolute;
  top: -30px;
  right: -32px;
  width: 40px;
  height: 40px;
}

.video-container > video {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1374px) {
  .initial-display h1 {
    font-size: 40px;
    /* width: 80%; */
  }

  .initial-display h1:after {
    display: none;
  }
}

@media screen and (width: 1280px) {
  .initial-text {
    padding: 1rem 0;
    margin-left: 1.5rem;
    top: 5%;
    padding-left: 3%;
  }
  .initial-text h1 {
    font-size: 2.7em;
    width: 70%;
  }

  .initial-display h1:after {
    display: none;
  }

  .initial-text p {
    font-size: 18px;
    width: 85%;
  }

  .bothButton {
    margin-top: -4%;
  }
}

@media screen and (max-width: 1279px) {
  .initial-text {
    padding: 1rem 0;
    margin-left: 1.5rem;
    top: 10%;
  }

  .initial-text h1 {
    font-size: 1.8em;
    width: 60%;
  }

  .initial-display h1:after {
    display: none;
  }

  .initial-text p {
    font-size: 16px;
    width: 60%;
    top: 1%;
  }

  .stud-cards > div {
    width: 30%;
  }

  .initial-text::before {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .container {
    padding: 0 1rem;
  }

  .masked-logo {
    position: absolute;
    z-index: 0;
  }

  .initial-text {
    font-size: 0.78rem;
    width: 94%;
    /* top: -15%; */
    /* margin-inline: auto; */
  }

  .carousel-slide {
    height: 400px;
  }

  .initial-text h1 {
    font-size: 2.2em;
    width: 80%;
    text-align: center;
  }

  .initial-text p {
    font-size: small;
    width: 90%;
    margin-top: 1rem;
    text-align: center;
  }

  .video-container {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-left: 0;
    left: 0;
    opacity: 1;
    align-items: center;
  }

  .video-container > video {
    height: auto;
    /* width: auto; */
  }

  .initial-text::before {
    display: none;
  }

  .bothButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-top: -5%;
  }

  .btn {
    margin-bottom: 0rem;
  }

  .btn-text,
  .social-handles,
  .btn {
    margin-inline: auto;
  }

  /* .initial-display {
    height: 100%;
    width: 100%;
    justify-content: center;
  } */
}

@media screen and (max-width: 701px) {
  .social-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .stud-cards > div {
    width: 90%;
  }

  .initial-text {
    top: -15%;
  }

  .initial-text h1::after {
    display: none;
  }

  .social-handles {
    margin-top: 0.5%;
  }
  .bothButton {
    margin-top: -12%;
  }

  .carousel-slide {
    height: 200px;
  }
}

@media screen and (min-width: 533px) and (max-width: 700px) {
  .social-handles {
    margin-top: 0.5%;
  }
  .bothButton {
    margin-top: -5%;
  }
}

@media screen and (max-width: 376px) {
  .container {
    padding: 0 0.4rem;
  }

  .social-handles {
    margin-top: -0.5%;
  }

  .bothButton {
    margin-top: -17%;
  }
}

@media screen and (max-width: 281px) {
  .bothButton {
    margin-top: -20%;
  }
}
