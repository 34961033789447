.breed-list {
  padding: 3rem 2rem;
}

.breed-list ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 3rem;
}

.breed-list li {
  font-size: 18px;
  margin-left: 1rem;
  cursor: pointer;
  color: #0f2d25;
  float: left;
}
