.breed-list {
  padding: 3rem 2rem;
}

.breed-list ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 3rem;
}

.breed-list li {
  font-size: 18px;
  margin-left: 1rem;
  cursor: pointer;
  color: #0f2d25;
  float: left;
}

.stud-cards {
  display: flex;
  justify-content: center;
  column-gap: 50px;
  row-gap: 20px;
  flex-wrap: wrap;
  border-radius: 20px;
}

.stud-card {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 1rem;
  transition: all 0.5s;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  column-gap: 0.5rem;
  align-items: center;
}

.stud-card:hover {
  transform: scale(1.05);
}

.stud-card h2 {
  font-family: "Crimson Text";
  font-weight: normal;
  margin: 0;
  z-index: 1;
}

.stud-card img {
  width: 100px;
  margin: 1rem;
  border-radius: 20px;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.details > div {
  display: flex;
  color: black;
  align-items: center;
  column-gap: 0.5rem;
}

.bold {
  font-weight: bold !important;
}

.icon {
  justify-content: center;
  font-size: x-large;
}

.male {
  color: blue;
}

.female {
  color: #e3419f;
}
