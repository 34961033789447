.main {
  /* padding: 4rem 4rem; */
}

.initial-display {
  padding: 3rem 0rem;
  position: relative;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  color: white;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* z-index: -2; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  /* width: 50%; */
  margin: 0;
  position: relative;
}

.initial-display h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../../assets/images/heading-dec-white.png");
  background-size: 50px;
  position: absolute;
  top: -35px;
  width: 50px;
  height: 50px;
}

/* .initial-display p {
  font-family: "Nunito";
} */

.initial-text {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  text-align: center;
}

.masked-logo {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  /* z-index: -1; */
}

.masked-logo img {
  position: relative;
  width: 70%;
}

.breed-footer {
  display: grid;
  grid-template-columns: min-content 100%;
  width: 100%;
}

.breed-footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-handles {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.social-handles svg {
  margin-bottom: 1rem;
}

.breed-details {
  display: flex;
  column-gap: 20px;
  padding: 2rem 2rem;
  border-radius: 20px;
  float: left;
  /* box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15); */
}

.breed-image {
  display: flex;
  align-items: center;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  /* max-width: 20%; */
}

.breed-image > div {
  width: 100%;
  min-height: 250px;
  /* min-height: 200px; */
  /* height: max-content; */
}

.breed-image img {
  width: 100%;
  /* height: 100%; */
}

.breed-image {
  border-radius: 20px;
}

.breed-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: scroll; */
}

.breed-details h1 {
  position: relative;
}

h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../../assets/images/heading-dec.png");
  background-size: 40px;
  position: absolute;
  top: -30px;
  width: 40px;
  height: 40px;
}

.breed-table table {
  border-spacing: 10px;
  width: max-content;
  /* border-spacing: 20px 20px; */
}

.breed-table td:first-child {
  font-weight: bold;
}

/* .breed-table h1 {
  font-family: "Crimson Text";
} */

.bottom {
  align-self: flex-end;
}

.rounded-buttons {
  display: flex;
  margin-top: 1rem;
  column-gap: 20px;
}

.rounded-buttons button {
  /* font-size: large; */
  /* font-family: "Crimson Text"; */
  /* margin-left: 20px; */

  font-size: 16px;
  color: #0f2d25;
  padding: 2px 5px;
  border: 1px solid #0f2d25;
  background: rgba(31, 255, 195, 0.1);
  border-radius: 100px;
}

.member-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  width: 100%;
}

.member-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  column-gap: 10px;
  margin-top: 2rem;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  /* width: max-content; */

  width: 400px;
  height: 100px;
}

.member-card > div {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-card img {
  max-width: 60px;
  height: 100%;
  border-radius: 8px;
}

.member-card h3,
.member-card p {
  /* font-family: "Nunito"; */
  margin: 0;
}

.member-card p {
  color: #5f5f5f;
}

/* .title {
  font-family: "Times New Roman";
} */

@media screen and (max-width: 1100px) {
  .member-cards {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  /* .main {
    padding: 4rem 4rem;
  } */

  .member-cards {
    justify-content: center;
  }

  .breed-table {
    align-items: center;
  }

  .breed-table h1 {
    margin-top: 2rem;
  }

  .breed-details {
    flex-direction: column;
    align-items: center;
  }

  .breed-details h1 {
    text-align: center;
  }

  .breed-image {
    width: 50%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  /* .main {
    padding: 4rem 1rem;
  } */

  .member-cards {
    width: 100%;
    justify-content: center;
    /* width: min-content; */
  }

  .member-card {
    width: 80%;
  }

  .breed-details {
    flex-direction: column;
    align-items: center;
    float: none;
  }

  .breed-image {
    width: 90%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}

@media screen and (max-width: 426px) {
  .initial-display h1:after {
    content: none;
  }
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

@media screen and (max-width: 350px) {
  .breed-image {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
  }

  .breed-table {
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 321px) {
  .initial-text {
    padding: 0;
  }
}
