.initial-display {
  padding: 3rem 0rem;
  position: relative;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  color: white;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* z-index: -2; */
}

.initial-display h1 {
  font-family: "Crimson Text";
  font-size: 45px;
  width: 50%;
  margin: 0;
  position: relative;
}

.initial-display h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../../assets/images/heading-dec-white.png");
  background-size: 50px;
  position: absolute;
  top: -35px;
  width: 50px;
  height: 50px;
}

.initial-display p {
  font-family: "Nunito";
  font-size: larger;
}

.initial-text {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  text-align: center;
}

.masked-logo {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  left: 0;
  z-index: -1;
}

.masked-logo img {
  position: relative;
  width: 70%;
}

.breed-footer {
  display: grid;
  grid-template-columns: min-content 100%;
  width: 100%;
}

.breed-footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-handles {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.social-handles svg {
  margin-bottom: 1rem;
}

.main {
  padding: 4rem 12rem;
}

.stud-details {
  display: flex;
  padding: 2rem 0rem;
  border-radius: 20px;
  justify-content: space-around;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
}

.stud-image {
  display: flex;
  width: 30%;
  /* max-width: 20%; */
}

.stud-image img {
  width: 100%;
}

.stud-image {
  border-radius: 20px;
}

.stud-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stud-table table {
  border-spacing: 10px;
}

.stud-table td:first-child {
  font-weight: bold;
}

.stud-table h1 {
  font-family: "Crimson Text";
  position: relative;
}

.stud-table h1:after {
  content: "";
  display: inline-block;
  background-image: url("../../../assets/images/heading-dec.png");
  background-size: 40px;
  position: absolute;
  top: -35px;
  width: 40px;
  height: 40px;
}

.bottom {
  align-self: flex-end;
}

.btn {
  /* align-items: center; */
  margin: auto;
  display: flex;
  overflow: visible;
  background-color: transparent;
  border: none;
  width: max-content;
  position: relative;
  padding: 1rem 2rem;
}

.btn-text {
  display: flex;
  align-items: center;
  transition: color 1s;
  font-size: larger;
  color: black;
}

.btn::before {
  border-radius: 100px;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 30%;
  height: 100%;
  background: rgba(192, 161, 71, 0.3);
  /* background: #0f2d25; */
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover::before {
  width: 100%;
  background: #0f2d25;
}

.btn:hover .btn-text {
  color: white;
}

.btn span {
  z-index: 10;
}

@media screen and (max-width: 1220px) {
  .main {
    padding: 4rem 8rem;
  }
}

@media screen and (max-width: 1025px) {
  .main {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 800px) {
  .main {
    padding: 4rem 4rem;
  }

  .stud-table h1 {
    margin-top: 2.5rem;
  }

  .stud-details {
    flex-direction: column;
    align-items: center;
  }

  .stud-details h1 {
    text-align: center;
  }

  .stud-image {
    width: 50%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  .initial-text {
    padding: 0 1rem;
  }

  .breed-footer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .breed-footer > div > button {
    margin-left: 0;
  }

  .breed-footer > div {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .breed-footer svg {
    margin-left: 1rem;
  }

  .social-handles {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .main {
    padding: 4rem 1rem;
  }

  .stud-details {
    flex-direction: column;
    align-items: center;
  }

  .stud-image {
    width: 90%;
  }

  .bottom {
    margin-top: 2rem;
    align-self: center;
  }
}

@media screen and (max-width: 321px) {
  .initial-text {
    padding: 0;
  }
}
