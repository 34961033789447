.drawer-call {
  position: absolute;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  visibility: visible;
  background-color: #0f2d25;
  right: -55px;
  z-index: -1;
  /* right: -39px; */
  /* top: 81px; */
  /* background-color: white; */
}

.drawer-call img {
  width: 40px;
}

.drawer {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 40px;
  background-color: transparent !important;
  box-shadow: none !important;
}

.view-controller {
  /* min-width: max-content; */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: linear-gradient(135deg, #0f2d25 11.2%, #217a77 100%);
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  /* background-color: white; */
}

.view-controller > div {
  padding: 1rem 2rem;
  font-family: "Crimson Text";
  font-weight: 600;
  font-size: 20px;
  /* color: #5f5f5f; */
  color: white;
  border-top: 2px solid #eeeeee;
}

.view-controller .active {
  color: #0f2d25;
  border-left: 3px solid #0f2d25;
  background-color: #e8eeed;
}

.drawer-call.open {
  right: 0;
}

/* @media screen and (max-width: 600px) {
  .drawer-call {
    right: -45px;
  }
  .drawer-call.open {
    right: 0;
  }

  .drawer-call img {
    width: 30px;
  }

  .drawer {
    margin-top: 20px;
  }

  .view-controller {
    flex-direction: column;
    align-items: stretch;
  }

  .view-controller > div {
    padding: 0.5rem 1rem;
    font-size: 16px;
  }
} */
