.breed-image {
  display: flex;
  align-items: flex-start;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  /* max-width: 20%; */
}

.breed-image img {
  width: 100%;
}

.breed-image {
  border-radius: 20px;
}

.breed-table {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin: 1rem 0;
  /* flex-direction: column; */
  /* justify-content: center; */
}

.breed-table table {
  border-spacing: 10px;
  width: max-content;
  /* border-spacing: 20px 20px; */
}

.breed-table td:first-child {
  font-family: "Crimson Text";
  font-size: 20px;
  font-weight: bold;
}

.breed-table td:last-child {
  color: #5f5f5f;
  font-family: "Numnito";
  font-size: 18px;
}

.breed-table h1 {
  font-family: "Crimson Text";
}

.rounded-buttons {
  display: flex;
  margin-top: 1rem;
  column-gap: 20px;
}

.rounded-buttons button {
  font-size: 16px;
  color: #0f2d25;
  padding: 5px 10px;
  border: 1px solid #0f2d25;
  background: rgba(31, 255, 195, 0.1);
  border-radius: 100px;
}

.member-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  /* width: 70%; */
}

.member-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  column-gap: 10px;
  margin-top: 2rem;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: max-content;
}

.member-card img {
  width: 40px;
}

.member-card h2 {
  text-transform: capitalize;
  font-family: "Nunito";
  margin: 0;
}

.bottom {
  margin-top: 2rem;
}

.btn {
  /* align-items: center; */
  display: flex;
  overflow: visible;
  background-color: transparent;
  border: none;
  width: max-content;
  position: relative;
  padding: 1rem 2rem;
}

.btn-text {
  display: flex;
  align-items: center;
  transition: color 1s;
  font-size: larger;
  color: #0f2d25;
}

.btn::before {
  border-radius: 100px;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 30%;
  height: 100%;
  background: rgba(192, 161, 71, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover::before {
  /* transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  width: 100%;
  background-color: #0f2d25;
}

.btn:hover .btn-text {
  color: white;
}

.btn span {
  z-index: 10;
}

/* Modal */
.modal-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Crimson Text";
}

.modal-title h2 {
  margin-bottom: 0;
}

.modal-row {
  display: flex;
  column-gap: 25px;
}

.modal-row > div {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
}

/* .modal-row > button {
  width: min-content !important;
} */

.modal-form label {
  font-family: "Crimson Text";
  font-size: 16px;
}

.modal-form input {
  padding: 0.7rem;
  border: 1px solid black;
  border-radius: 8px;
  font-size: large;
}

.modal-second-title {
  width: 50%;
  font-family: "Crimson Text";
}

.modal-second-title h2 {
  font-size: 30px;
  margin-bottom: 0;
}

/* .password-form {
} */

.password-form-input {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.password-form-input input {
  width: 50%;
}

.close {
  padding: 0.5rem 3rem;
  background-color: white;
  color: #0f2d25;
  border-radius: 100px;
  border: 1px solid #0f2d25;
  font-size: 18px;
  font-family: "Crimson Text";
  cursor: pointer;
}

.submit {
  padding: 0.5rem 3rem;
  background-color: #0f2d25;
  color: white;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-family: "Crimson Text";
  cursor: pointer;
}

/* Modal */

@media screen and (max-width: 1200px) {
  .breed-table {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .breed-image {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    /* max-width: 20%; */
  }
}

@media screen and (max-width: 500px) {
  .breed-table {
    align-items: center;
  }

  .breed-table table {
    width: min-content;
  }

  .member-cards {
    justify-content: center;
  }

  .member-card {
    width: 80%;
    justify-content: center;
  }

  .rounded-buttons {
    align-self: center;
  }

  .bottom {
    align-self: center;
  }

  /* Modal */

  .modal-row {
    flex-direction: column;
    row-gap: 20px;
  }

  .close,
  .submit {
    width: 50%;
  }

  /* Modal */
}

@media screen and (max-width: 366px) {
  .breed-table tr {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    text-align: center;
    /* row-gap: 10px; */
  }
}
