.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title h3 {
  font-family: "Crimson Text";
  margin-bottom: 0;
}

.title-text {
  font-family: "Nunito";
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 0;
}

.title-text span {
  color: #c0a147;
}

.inputs {
  display: flex;
  column-gap: 50px;
}

.inputs > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.horse-lease label {
  font-family: "Crimson Text";
  font-size: 16px;
  font-weight: 600;
}

.inputs select {
  padding: 0.7rem;
  border: 1px solid black;
  border-radius: 8px;
  font-size: large;
}

.horse-lease textarea {
  /* margin-top: 1rem; */
  width: 100%;
  padding: 0.7rem;
  border: 1px solid black;
  border-radius: 8px;
  font-size: large;
}

.notice {
  margin-top: 1rem;
}

.notice p {
  font-family: "Nunito";
}

.notice > div {
  display: flex;
  column-gap: 20px;
}

.notice-para {
  max-width: 55%;
}

.notice-para hr {
  margin: 0;
}

.fee-card {
  border: 1px solid #c0a147;
  padding: 0.7rem 2rem;
  background: rgba(192, 161, 71, 0.05);
  width: max-content;
  max-width: 100%;
  text-align: center;
}

.close {
  padding: 0.5rem 3rem;
  background-color: white;
  color: #0f2d25;
  border-radius: 100px;
  border: 1px solid #0f2d25;
  font-size: 18px;
  font-family: "Crimson Text";
  cursor: pointer;
}

.submit {
  padding: 0.5rem 3rem;
  background-color: #0f2d25;
  color: white;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-family: "Crimson Text";
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .modal-title h3 {
    font-size: 21px;
  }

  .notice > div {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .notice-para {
    max-width: 100%;
  }

  .inputs {
    flex-direction: column;
    row-gap: 20px;
  }

  .registration-details {
    flex-direction: column;
  }

  .close,
  .submit {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .inputs {
    flex-direction: column;
    row-gap: 20px;
  }
}
